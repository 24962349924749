import { useQuery } from 'react-query';

import * as utils from 'utils';

import { quoteBindAxios } from '../quoteBind.api';

export const useGetAutocompleteOptions = ({ product, dataIndex, dataSource, valueIndex, enabled }) =>
  useQuery({
    queryKey: ['autocompleteOptions', product, dataIndex, dataSource, valueIndex],
    queryFn: async () => {
      const response = await quoteBindAxios.get(`products/${product}/data/${dataIndex}`, {
        params: { dataSource, valueIndex },
      });
      return response.data;
    },
    onError: (error) => {
      const errorParams = {
        file: 'FormAutocompleteMuiAsync.js',
        message: 'API fetch error, initial fetchData',
      };

      utils.api.handleError(error, errorParams);
    },
    enabled,
  });
