import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// mui
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Drawer,
  IconButton,
  makeStyles,
  Popover,
  Typography,
  useTheme,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import ForwardIcon from '@material-ui/icons/Forward';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { Button, FormGrid, ModalDialog, Restricted, Status, Tooltip, Translate } from 'components';
import { EndorsementComparison } from 'components/EndorsementComparison';
import { quoteInformation } from 'modules/QuoteBind/RiskQuotes/QuoteCard';
import QuoteIssues from 'modules/QuoteIssues/QuoteIssues';

import {
  ENDORSEMENT_CHANGE_TYPE,
  RISK_QUOTE_STATUS_BLOCKED,
  RISK_QUOTE_STATUS_BOUND,
  RISK_QUOTE_STATUS_DECLINED,
  RISK_QUOTE_STATUS_QUOTED,
  RISK_QUOTE_STATUS_REFERRED,
  RISK_QUOTE_STATUS_REJECTED,
  ROLE_BROKER,
  ROLE_PRODUCER,
  ROLE_UNDERWRITER,
} from 'consts';
import {
  QB_RISK_ENDORSEMENTS,
  useDeclineEndorsementQuote,
  useDownloadEndorsement,
  useEndorsementQuoteResponse,
  usePutEndorsements,
  useRequestDismissIssueEndorsement,
  useRequestToBindEndorsement,
} from 'lib/quoteBind';
import { showModal } from 'stores';
import * as utils from 'utils';

import { QuoteLine } from '../PolicyCard';
import styles from '../PolicyCard.style';

import { EffectiveDateSelection } from './EffectiveDateSelection';
import { EndorsementConflictResolution } from './EndorsementConflictResolution';

export const EndorsementQuote = ({
  quote,
  policyCurrency,
  policy,
  hasBackdateEndorsement,
  isEndorsementEnabled,
  hasEndorsementAdditionalPremium,
  latestEffectiveEndorsementDate,
  handleReQuoteEndorsement,
}) => {
  const dispatch = useDispatch();
  const classes = makeStyles(styles, { name: 'EndorsementQuoteCard' })();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { riskType, canCurrentUserBind, canCurrentUserDismissIssues } = { ...policy };
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorElRequote, setAnchorElRequote] = useState(null);

  const [open, setOpen] = useState(false);

  const handleRequoteClick = (event) => {
    setAnchorElRequote(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleCloseRequote = () => {
    setOpen(false);
  };

  const [isConflictResolveOpen, setConflictResolveOpen] = useState(false);

  const { mutate: bindEndorsementQuote, isLoading: isLoadingBind } = useEndorsementQuoteResponse();
  const { mutate: acceptEndorsement, isLoading: isLoadingAcceptQuote } = usePutEndorsements();
  const { mutate: declineEndorsement, isLoading: isLoadingDecline } = useDeclineEndorsementQuote();
  const { mutate: downloadEndorsementQuote, isLoading: isDownloading } = useDownloadEndorsement();
  const { mutate: requestToBindEndorsement, isLoading: isLoadingRequestToBind } = useRequestToBindEndorsement();
  const { mutate: requestDismissIssueEndorsement, isLoading: isLoadingDismissIssues } = useRequestDismissIssueEndorsement();

  const isWaiting =
    isLoadingAcceptQuote || isLoadingBind || isLoadingDecline || isLoadingRequestToBind || isLoadingDismissIssues || isDownloading;

  const quoteInfo = quote?.summaryValues || [];
  const additionalPremiumTotal = quote?.additionalPremiums?.reduce((acc, curr) => acc + curr.premium, 0);

  const quoteStatus = quote?.response?.responseStatus ? quote.response.responseStatus : quote.status;
  let quoteStatusString = quoteStatus ? utils.string.t(`QBstatus.${quoteStatus.toLowerCase()}`) : '-';
  quoteStatusString = quoteStatusString === 'Quoted' ? utils.string.t('status.quote') : quoteStatusString;

  const statusBackgroundColor = theme.palette.status[quoteStatus?.toLowerCase()]
    ? theme.palette.status[quoteStatus.toLowerCase()]
    : theme.palette.status.default;
  const statusColor = utils.color.contrast(statusBackgroundColor, 0.6);

  const premiumValue = `${policyCurrency || ''} ${utils.string.t('format.number', {
    value: { number: quote.premium, default: '-' },
  })}`;

  const proRataValue = `${policyCurrency || ''} ${utils.string.t('format.number', {
    value: { number: quote.proRata, default: '-' },
  })}`;

  const additionalPremiumValue = `${policyCurrency || ''} ${utils.string.t('format.number', {
    value: { number: additionalPremiumTotal, default: '-' },
  })}`;

  const hasIssues = !isEmpty(quote.issues);
  const isQuoted = quoteStatus === RISK_QUOTE_STATUS_QUOTED;
  const isBlocked = quoteStatus === RISK_QUOTE_STATUS_BLOCKED;
  const isIssuesBlocked = quoteStatus === RISK_QUOTE_STATUS_BLOCKED && hasIssues;
  const isConflictBlocked = quoteStatus === RISK_QUOTE_STATUS_BLOCKED && !isEmpty(quote.conflicts);
  const hasConflicts = !isEmpty(quote.conflicts);
  const isReferred = quoteStatus === RISK_QUOTE_STATUS_REFERRED;
  const isRejected = quoteStatus === RISK_QUOTE_STATUS_REJECTED;
  const isDeclined = quoteStatus === RISK_QUOTE_STATUS_DECLINED;
  const endorsementCount = quote?.endorsementCount;
  const showReferIssues = (isIssuesBlocked || isRejected || (isReferred && hasIssues)) && !isDeclined;
  const quoteIssues = quote?.issues || [];
  const comments = quote?.comments || '';
  const showPriceForProducer = !(isBlocked || isReferred || isDeclined || isRejected);
  const producerDisplayPrice = utils.string.t(`products.premiumPlaceholder.${quoteStatus.toLowerCase()}`);

  const issuesData = {
    hasIssues: quoteIssues.length > 0,
  };

  const requestedToBind = quote?.requestedToBind;
  const hasTemplates = quote?.hasTemplates;

  const isExpired = quote.validUntil && utils.date.isBefore(quote.validUntil);

  const postPatchRiskData = { ...quote?.postPatchRisk, comments };

  const handleBindClick = () => {
    const bindInfo = {
      quoteId: quote.id,
      responseStatus: RISK_QUOTE_STATUS_BOUND,
    };
    bindEndorsementQuote(bindInfo);
  };

  const requestedToDismissIssues = quote?.requestedToDismissIssues || false;
  const isExtensionOrCancellation =
    quote?.changeType === ENDORSEMENT_CHANGE_TYPE.CANCELLATION || quote?.changeType === ENDORSEMENT_CHANGE_TYPE.EXTENSION;

  const handleRequestToBind = () => {
    requestToBindEndorsement({ endorsementId: quote.id });
  };

  const handleRequestDismissIssues = (quoteId) => {
    requestDismissIssueEndorsement({ endorsementId: quoteId });
  };

  const handleDownloadQuote = (quote) => {
    downloadEndorsementQuote({ id: quote.id });
  };

  const handleDeclineQuote = () => {
    declineEndorsement({ endorsementId: quote.id });
  };

  const handleAcceptQuote = () => {
    acceptEndorsement({ endorsementId: quote.id });
  };

  const handleRiskRefresh = () => {
    queryClient.invalidateQueries([QB_RISK_ENDORSEMENTS, quote.riskId]).then(() => {
      drawerOpen && setDrawerOpen(false);
    });
  };

  const handleOpenConflictResolve = () => {
    setConflictResolveOpen(true);
  };

  const handleCloseConflictResolve = () => {
    setConflictResolveOpen(false);
  };

  const toggleDrawer = (value) => {
    setDrawerOpen(value);
  };
  const handlePatchQuote = ({ quote }) => {
    dispatch(
      showModal({
        component: 'EDIT_ENDORSEMENT_QUOTE',
        props: {
          title: 'risks.updateEndorsementQuote',
          fullWidth: true,
          maxWidth: 'xs',
          componentProps: {
            quote,
            hasEndorsementAdditionalPremium,
          },
        },
      })
    );
  };

  const handlePatchQuoteProRata = ({ quote }) => {
    dispatch(
      showModal({
        component: 'EDIT_ENDORSEMENT_QUOTE_PRO_RATA',
        props: {
          title: 'risks.updateEndorsementQuote',
          fullWidth: true,
          maxWidth: 'xs',
          componentProps: {
            quote,
          },
        },
      })
    );
  };

  const EditQuote = ({ isDisabled }) =>
    (isReferred || isQuoted) && canCurrentUserDismissIssues && !requestedToBind && !issuesData?.hasIssues ? (
      <Button
        data-testid="edit-pro-rata-quote-button"
        size="xsmall"
        icon={EditIcon}
        variant="text"
        nestedClasses={{ icon: classes.icon }}
        onClick={() =>
          handlePatchQuote({
            quote,
          })
        }
        disabled={isDisabled}
      />
    ) : null;

  const EditProRata = ({ isDisabled }) =>
    (isReferred || isQuoted) && canCurrentUserDismissIssues && !requestedToBind && !issuesData?.hasIssues ? (
      <Button
        data-testid="edit-quote-button"
        size="xsmall"
        icon={EditIcon}
        variant="text"
        nestedClasses={{ icon: classes.icon }}
        onClick={() =>
          handlePatchQuoteProRata({
            quote,
          })
        }
        disabled={isDisabled}
      />
    ) : null;

  const subheader = (
    <Box style={{ display: 'flex', alignItems: 'center', flexFlow: 'wrap' }}>
      {requestedToBind && isQuoted ? (
        <Status
          size="xs"
          text={<Translate label={utils.string.t('risks.requestToBindStatus')} />}
          status="quoted"
          data-testid="quote-status"
          style={{
            backgroundColor: statusBackgroundColor,
            color: statusColor,
          }}
        />
      ) : (
        <Status
          size="xs"
          text={<Translate label={`${quoteStatusString}`} />}
          status={quoteStatus.toLowerCase()}
          data-testid="quote-status"
          style={{
            backgroundColor: statusBackgroundColor,
            color: statusColor,
          }}
        />
      )}
      {quote?.changeType === ENDORSEMENT_CHANGE_TYPE.CANCELLATION || quote?.changeType === ENDORSEMENT_CHANGE_TYPE.EXTENSION ? (
        <Status
          size="xs"
          text={
            <Translate
              label={
                quote?.cancellationReason && quote?.changeType === ENDORSEMENT_CHANGE_TYPE.CANCELLATION
                  ? quote.cancellationReason
                  : utils.string.t(`risks.${quote?.changeType}`)
              }
            />
          }
          data-testid="quote-status-cancellation"
          status="alert"
          style={{
            marginLeft: 2,
            backgroundColor:
              quote?.changeType === ENDORSEMENT_CHANGE_TYPE.CANCELLATION ? theme.palette.status.cancelled : theme.palette.status.bound,
          }}
        />
      ) : null}
    </Box>
  );

  const downloadTemplate = hasTemplates ? (
    <Box position="relative">
      <Button
        data-testid="download-quote-button"
        size="xsmall"
        icon={!isDownloading ? PictureAsPdfIcon : null}
        color="secondary"
        text={utils.string.t('risks.download')}
        nestedClasses={{ btn: isDownloading ? classes.buttonDownloading : '', icon: classes.icon }}
        onClick={() => handleDownloadQuote(quote)}
      />
      {isDownloading ? <CircularProgress color="primary" size={16} className={classes.buttonProgress} /> : null}
    </Box>
  ) : null;

  const EndorsementQuoteCardAction = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleIssuesPopoverClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'issue-popover' : undefined;

    if (isQuoted && !isBlocked) {
      return (
        <CardActions disableSpacing classes={{ root: classes.cardActions }}>
          <Box px={1} pb={0.5} display="flex" flexDirection="row" justifyContent="space-between" width="100%">
            {downloadTemplate}
            <Box display="flex" flexDirection="row" ml="auto" flexWrap={canCurrentUserBind ? 'no-wrap' : 'wrap'} justifyContent="flex-end">
              {canCurrentUserBind ? (
                <>
                  <Restricted include={[ROLE_BROKER, ROLE_PRODUCER]}>
                    <Box mb={1}>
                      <Button
                        data-testid="bind-quote-button"
                        size="xsmall"
                        style={{ backgroundColor: theme.palette.status.bound }}
                        icon={CheckIcon}
                        color="primary"
                        text={utils.string.t('risks.bind')}
                        nestedClasses={{ icon: classes.icon }}
                        onClick={(e) => handleBindClick(e)}
                        disabled={isBlocked || isWaiting}
                      />
                    </Box>
                  </Restricted>
                  <Restricted include={[ROLE_UNDERWRITER]}>
                    <Box mb={1}>
                      <Button
                        data-testid="bind-quote-button"
                        size="xsmall"
                        style={{ backgroundColor: theme.palette.status.bound }}
                        icon={CheckIcon}
                        color="primary"
                        text={utils.string.t('risks.bind')}
                        nestedClasses={{ icon: classes.icon }}
                        onClick={(e) => handleBindClick(e, quote, false)}
                        disabled={isBlocked || isWaiting}
                      />
                    </Box>
                  </Restricted>
                </>
              ) : (
                <>
                  {!requestedToBind ? (
                    <Box whiteSpace="nowrap" mb={1}>
                      <Button
                        data-testid="request-bind-quote-button"
                        size="xsmall"
                        style={{ backgroundColor: theme.palette.status.bound }}
                        icon={CheckIcon}
                        color="primary"
                        text={utils.string.t('risks.requestToBind')}
                        nestedClasses={{ icon: classes.icon }}
                        onClick={() => handleRequestToBind(quote, false)}
                        disabled={isBlocked || isWaiting}
                      />
                    </Box>
                  ) : null}
                </>
              )}
              <Restricted include={[ROLE_BROKER, ROLE_PRODUCER, ROLE_UNDERWRITER]}>
                {!isExpired && !isBlocked ? (
                  <Box ml={1}>
                    <Button
                      data-testid="decline-quote-button"
                      size="xsmall"
                      icon={BlockIcon}
                      color="primary"
                      danger
                      text={utils.string.t('risks.decline')}
                      nestedClasses={{ icon: classes.icon }}
                      onClick={() => handleDeclineQuote(quote.id)}
                      disabled={isBlocked || isWaiting}
                    />
                  </Box>
                ) : null}
              </Restricted>
            </Box>
          </Box>
        </CardActions>
      );
    }

    if (isReferred && canCurrentUserDismissIssues && !hasIssues) {
      return (
        <CardActions disableSpacing classes={{ root: classes.cardActions }}>
          <Box px={1} pb={0.5} display="flex" width="100%" flexDirection="row" justifyContent="space-between">
            {canCurrentUserDismissIssues ? downloadTemplate : null}
            <Box ml="auto" display="flex" flexWrap="wrap" justifyContent="flex-end">
              <Box mr={1} mb={1}>
                <Button
                  data-testid="decline-quote-button"
                  size="xsmall"
                  icon={BlockIcon}
                  color="primary"
                  danger
                  text={utils.string.t('risks.decline')}
                  nestedClasses={{ icon: classes.icon }}
                  onClick={() => handleDeclineQuote(quote.id)}
                  disabled={isBlocked || isWaiting || isLoadingAcceptQuote}
                />
              </Box>
              <Box mb={1}>
                <Button
                  data-testid="accept-quote-button"
                  size="xsmall"
                  icon={CheckIcon}
                  color="primary"
                  text={utils.string.t('risks.accept')}
                  nestedClasses={{ icon: classes.icon }}
                  onClick={() => handleAcceptQuote(quote.id)}
                  disabled={isBlocked || isWaiting || isLoadingAcceptQuote}
                />
              </Box>
            </Box>
          </Box>
        </CardActions>
      );
    }

    if (showReferIssues) {
      return (
        <CardActions disableSpacing classes={{ root: classes.cardActions }} style={{ position: 'relative' }}>
          <Box px={1} pb={0.5} display="flex" width="100%" flexDirection="row" justifyContent="space-between">
            {canCurrentUserDismissIssues && isReferred ? downloadTemplate : null}
            <Box ml="auto" display="flex" flexWrap="wrap" justifyContent="flex-end">
              {!canCurrentUserDismissIssues && !requestedToDismissIssues && isIssuesBlocked && quote?.id ? (
                <Box mr={1} mb={1}>
                  <Button
                    data-testid="refer-quote-button"
                    size="xsmall"
                    style={{ backgroundColor: isWaiting ? '' : theme.palette.status.referred, color: 'white' }}
                    icon={ForwardIcon}
                    color="primary"
                    text={utils.string.t('risks.refer')}
                    nestedClasses={{ icon: classes.icon }}
                    onClick={() => handleRequestDismissIssues(quote.id)}
                    disabled={isWaiting}
                  />
                </Box>
              ) : null}
              {quoteIssues?.length > 0 ? (
                <Box mb={1} style={{ position: 'relative' }}>
                  <Button
                    data-testid="issues-quote-button"
                    size="xsmall"
                    icon={InfoOutlinedIcon}
                    color="primary"
                    danger
                    style={{ position: 'relative' }}
                    text={utils.string.t('risks.issues')}
                    nestedClasses={{ icon: classes.icon }}
                    onClick={(e) => (quoteIssues?.length > 1 ? toggleDrawer(true) : handleIssuesPopoverClick(e))}
                  />
                  {quoteIssues?.length === 1 ? (
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Box p={2} maxWidth={340}>
                        <Box value="issues" data-testid="tab-content-issues">
                          {hasIssues && (
                            <QuoteIssues
                              riskIssues={issuesData?.issues}
                              quoteIssues={quoteIssues}
                              insuredSanctionsCheckResult={issuesData?.insuredSanctionsCheckResult}
                              reInsuredSanctionsCheckResult={issuesData?.reInsuredSanctionsCheckResult}
                              canCurrentUserDismissIssues={canCurrentUserDismissIssues}
                              handleRiskRefresh={handleRiskRefresh}
                              quoteId={quote?.id}
                            />
                          )}
                        </Box>
                      </Box>
                    </Popover>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </Box>
        </CardActions>
      );
    }

    if (isConflictBlocked) {
      return (
        <CardActions disableSpacing classes={{ root: classes.cardActions }}>
          <Box px={1} pb={0.5} display="flex" width="100%" flexDirection="row" justifyContent="space-between">
            <Box ml="auto" display="flex" flexWrap="wrap" justifyContent="flex-end">
              <Box mr={1} mb={1}>
                <Button
                  data-testid="resolve-conflicts-button"
                  size="xsmall"
                  icon={WarningRoundedIcon}
                  color="primary"
                  danger
                  text={utils.string.t('risks.resolveConflicts')}
                  nestedClasses={{ icon: classes.icon }}
                  style={{ backgroundColor: '#EDAC00' }}
                  onClick={() => handleOpenConflictResolve()}
                  disabled={isWaiting || isLoadingAcceptQuote}
                />
              </Box>
            </Box>
          </Box>
        </CardActions>
      );
    }

    return (
      <CardActions disableSpacing classes={{ root: classes.cardActions }}>
        <Box px={1} pb={0.5} display="flex" width="100%" flexDirection="row" justifyContent="space-between" />
      </CardActions>
    );
  };

  return (
    <>
      {quote?.conflicts?.length > 0 ? (
        <ModalDialog
          title="Conflicts"
          fullWidth
          disableBackdropClick
          enableFullScree={false}
          maxWidth="md"
          cancelHandler={handleCloseConflictResolve}
          hideModal={handleCloseConflictResolve}
          visible={isConflictResolveOpen}
        >
          <EndorsementConflictResolution conflicts={quote.conflicts} riskType={riskType} />
        </ModalDialog>
      ) : null}
      {isBlocked || isRejected || isReferred ? (
        <Drawer anchor="right" open={drawerOpen} style={{ zIndex: 10000 }} onClose={() => toggleDrawer(false)}>
          <Box p={2}>
            <Box value="issues" data-testid="tab-content-issues">
              {issuesData?.hasIssues && (
                <QuoteIssues
                  riskIssues={issuesData?.issues || []}
                  quoteIssues={quoteIssues || []}
                  insuredSanctionsCheckResult={issuesData?.insuredSanctionsCheckResult}
                  reInsuredSanctionsCheckResult={issuesData?.reInsuredSanctionsCheckResult}
                  canCurrentUserDismissIssues={canCurrentUserDismissIssues}
                  handleRiskRefresh={handleRiskRefresh}
                  quoteId={quote?.id}
                />
              )}
            </Box>
          </Box>
        </Drawer>
      ) : null}
      <Card classes={{ root: classes.card }} data-testid="endorsement-quote">
        <CardHeader
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Avatar style={{ backgroundColor: statusBackgroundColor }} className={classes.endorsementIndex}>
                  {endorsementCount || <AddIcon size="xsmall" />}
                </Avatar>
                <Box display="flex" flexDirection="column">
                  <Typography variant="h3" className={classes.cardFacility} style={{ textAlign: 'left' }}>
                    {utils.string.t('format.date', {
                      value: { date: quote.effectiveFrom, format: 'll', default: '-' },
                    })}
                  </Typography>
                  {subheader}
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Restricted include={[ROLE_BROKER, ROLE_PRODUCER]}>
                  {isEndorsementEnabled && !isExtensionOrCancellation && (
                    <Tooltip title={utils.string.t('app.reQuote')} rich interactive>
                      <IconButton size="small" onClick={handleRequoteClick}>
                        <AutorenewIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <EffectiveDateSelection
                    open={open}
                    anchorEl={anchorElRequote}
                    policy={policy}
                    hasBackdateEndorsement={hasBackdateEndorsement}
                    latestEffectiveEndorsementDate={latestEffectiveEndorsementDate}
                    endorsementId={quote?.id}
                    handleAddEndorsement={handleReQuoteEndorsement}
                    handleClose={handleCloseRequote}
                  />
                </Restricted>
                {hasConflicts ? (
                  <IconButton size="small" onClick={() => handleOpenConflictResolve()}>
                    <WarningRoundedIcon style={{ color: '#EDAC00' }} />
                  </IconButton>
                ) : null}
                <EndorsementComparison
                  prePatchRisk={quote?.prePatchRisk}
                  postPatchRisk={postPatchRiskData}
                  jsonPatchData={quote?.jsonPatchData}
                  effectiveFrom={quote?.effectiveFrom}
                  riskType={riskType}
                />
                {quoteInfo?.length ? (
                  <Tooltip title={quoteInformation(quoteInfo, isQuoted)} rich interactive style={{ display: 'flex', alignItems: 'center' }}>
                    <InfoOutlinedIcon size="small" data-testid="quote-summary-icon" />
                  </Tooltip>
                ) : null}
              </Box>
            </Box>
          }
          style={{
            borderBottom: `4px solid ${statusBackgroundColor}`,
          }}
        />
        <CardContent classes={{ root: classes.cardContent }}>
          <Box>
            <FormGrid container spacing={1} alignItems="center" style={{ padding: 16 }}>
              <QuoteLine title={utils.string.t('app.premium')} titleVariant="h5" />
              <Restricted include={[ROLE_BROKER, ROLE_UNDERWRITER]}>
                <QuoteLine
                  title={utils.string.t('app.fullTerm')}
                  value={premiumValue}
                  titleVariant="h5"
                  indent
                  editIcon={<EditQuote isDisabled={isBlocked || isWaiting} />}
                />
                <QuoteLine title={utils.string.t('app.additional')} value={additionalPremiumValue} titleVariant="h5" indent />
                <QuoteLine
                  title={utils.string.t('app.proRata')}
                  value={proRataValue}
                  titleVariant="h5"
                  indent
                  editIcon={<EditProRata isDisabled={isBlocked || isWaiting} />}
                />
              </Restricted>
              <Restricted include={[ROLE_PRODUCER]}>
                <QuoteLine
                  title={utils.string.t('app.fullTerm')}
                  value={showPriceForProducer ? premiumValue : producerDisplayPrice}
                  titleVariant="h5"
                  indent
                  editIcon={<EditQuote isDisabled={isBlocked || isWaiting} />}
                />
                <QuoteLine
                  title={utils.string.t('app.proRata')}
                  value={showPriceForProducer ? proRataValue : producerDisplayPrice}
                  titleVariant="h5"
                  indent
                  editIcon={<EditProRata isDisabled={isBlocked || isWaiting} />}
                />
              </Restricted>
              <QuoteLine
                title={utils.string.t('risks.effectiveFrom')}
                value={utils.string.t('format.date', {
                  value: { date: quote.effectiveFrom, format: 'll', default: '-' },
                })}
              />
            </FormGrid>
          </Box>
          <Box classes={{ root: classes.borderTop }}>
            <FormGrid container spacing={1} alignItems="center" style={{ padding: 16 }}>
              <QuoteLine
                title={utils.string.t('app.createdAt')}
                value={utils.string.t('format.date', {
                  value: { date: quote.createdAt, format: 'll', default: '-' },
                })}
              />
              <QuoteLine
                title={utils.string.t('app.validUntil')}
                value={utils.string.t('format.date', {
                  value: { date: quote.validUntil, format: 'll', default: '-' },
                })}
              />
            </FormGrid>
          </Box>
        </CardContent>
        <EndorsementQuoteCardAction />
      </Card>
    </>
  );
};
