/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import * as Yup from 'yup';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';

import { FormActions, FormFields, RiskData, Translate } from 'components';

import config from 'config';
import * as utils from 'utils';

import RenderStep from './RenderStep';

import styles from './AddEditQuoteBind.style';

function getSteps(steps) {
  return steps.map((step) => capitalize(step));
}

const MultiStepForm = ({
  fields,
  isReQuote,
  isEndorsement,
  defaultValues,
  riskDataValues,
  handleSubmit,
  definitionsFields,
  productType,
  handleDraftSave,
  fullScreen,
}) => {
  const classes = makeStyles(styles, { name: 'MultiStepForm' })({ fullScreen });
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [stepFields, setStepFields] = useState([]);
  const [progress, setProgress] = useState(0);
  const [validationSchema, setValidationSchema] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setProgress(activeStep ? (activeStep / stepFields.length) * 100 : 0);
  }, [activeStep]);

  const methods = useForm({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(utils.form.getValidationSchema(validationSchema)),
    mode: 'onChange',
  });

  const { formState, getValues } = methods;

  const groups = utils.risk.getGroups(fields);
  const groupsTitles = Object.entries(groups).map((group, index) => startCase(group[0]));

  const groupsIDs = Object.entries(groups).map((group, index) => group[0]);

  const steps = [...getSteps(groupsTitles), utils.string.t('risks.addRiskConfirmation')];

  const validateFields = useMemo(
    () =>
      fields.filter((field) => {
        const condition = utils.risk.getCondition(field, fields);
        const isValid = !condition || (condition && utils.risk.isConditionValid(condition, methods.watch(condition.name)));

        if (field.name === 'expiryDate') {
          const inceptionDate = getValues('inceptionDate');
          const minExpiryDate = utils.date.addDays(utils.date.startOf(inceptionDate, 'day'), 1);
          if (utils.date.isValid(inceptionDate)) {
            field.validation = Yup.date().nullable().min(inceptionDate, utils.string.t('risks.expiryDateInvalidMin'));
            field.muiPickerProps = {
              ...field.minExpiryDate,
              minDate: minExpiryDate,
            };
          }
        }

        return !!isValid;
      }),
    [fields, formState]
  );

  const copyFieldNames = useMemo(() => {
    const copyFields = fields
      .map((field) => {
        if (field.copyValue) return field.copyValue;
        return null;
      })
      .filter((field) => field !== null);

    return Array.from(new Set(copyFields));
  }, [fields]);

  const copyFieldsValues = methods.watch(copyFieldNames);

  useEffect(() => {
    fields.forEach((field) => {
      if (field.copyValue) {
        const copyValueIndex = copyFieldNames.indexOf(field.copyValue);
        methods.setValue(field.name, copyFieldsValues[copyValueIndex]);
      }
    });
  }, [copyFieldsValues, fields, methods]);

  useEffect(() => {
    const fieldGroups = { ...groups };
    const fieldGroupsArray = Object.entries(fieldGroups).map((group, index) => group);
    setStepFields(fieldGroupsArray);
  }, [fields]);

  useEffect(() => {
    const updateSchema = !isEqual(
      validationSchema.filter((field) => field.name),
      validateFields.filter((field) => field.name)
    );
    if (updateSchema) setValidationSchema(validateFields);
  }, [formState]);

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps() - 1;

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleNext = async () => {
    const schemaFields = stepFields[activeStep] ? stepFields[activeStep][1] : [];

    const validateFields = schemaFields
      .filter((field) => {
        const condition = utils.risk.getCondition(field, fields);
        const isValid = !condition || (condition && utils.risk.isConditionValid(condition, methods.watch(condition.name)));

        return !!isValid;
      })
      .map((field) => field.name)
      .filter((field) => !!field);

    const isStepValid = await methods.trigger(validateFields);

    if (isStepValid) {
      handleComplete();
      // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      const nextStep = steps.findIndex((step, i) => !(i in completed)) > -1 ? steps.findIndex((step, i) => !(i in completed)) : 0;

      const newActiveStep = isLastStep() && !allStepsCompleted() ? nextStep : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSave = async () => {
    const data = methods.getValues();
    if (data.clientId && utils.generic.isValidObject(data.clientId)) {
      handleDraftSave(data);
    } else {
      methods.trigger('clientId', { shouldFocus: true });
    }
  };

  const onSubmit = async (data) => {
    const res = await handleSubmit(data);
    if (res?.result?.data) {
      const { id } = res.result.data;

      !isReQuote && history.push(`${config.routes.quoteBind.riskDetails}/${id}`);
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  function getStepContent(step, fieldsArray) {
    return fieldsArray[1]?.length ? (
      <RenderStep
        fieldsArray={fieldsArray[1]}
        definitionsFields={definitionsFields}
        classes={classes}
        options={{ gridSize: { xs: 12, md: 4, xl: 4 } }}
        productType={productType}
      />
    ) : null;
  }

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} onClick={handleStep(index)} completed={completed[index]}>
            <StepLabel
              StepIconProps={{
                classes: { root: classes.iconContainer },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <LinearProgress variant="determinate" value={progress} className={classes.linearProgress} />
      <FormProvider {...methods}>
        <Box>
          <form>
            <div type="dialog" autoComplete="off" data-testid="risk-form">
              <FormFields type="dialog" nestedClasses={{ root: isLastStep() ? classes.summary : classes.formContent }}>
                {stepFields[activeStep] ? (
                  getStepContent(activeStep, stepFields[activeStep])
                ) : isLastStep() ? (
                  <RiskData
                    allStepsCompleted={allStepsCompleted()}
                    fields={fields}
                    productType={productType}
                    handleStep={handleStep}
                    groups={groupsIDs}
                    definitionsFields={fields}
                    riskDataValues={riskDataValues}
                    isReQuote={isReQuote}
                    isEndorsement={isEndorsement}
                    productType={productType}
                  />
                ) : null}
              </FormFields>
              <FormActions type="dialog">
                {!isReQuote ? (
                  <Button onClick={onSave} color="secondary" variant="contained">
                    {utils.string.t('app.saveDraft')}
                  </Button>
                ) : null}
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  {utils.string.t('app.backToPrevious')}
                </Button>
                <Button
                  variant="contained"
                  disabled={formState.isSubmitting}
                  color="primary"
                  onClick={() => (isLastStep() && formState.isValid ? methods.handleSubmit(onSubmit, onError)() : handleNext())}
                >
                  {isLastStep() && formState.isValid ? <Translate label="app.submit" /> : `Next`}
                </Button>
              </FormActions>
            </div>
          </form>
        </Box>
      </FormProvider>
    </div>
  );
};

export default MultiStepForm;
