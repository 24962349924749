import { useGetAutocompleteOptions } from 'lib/quoteBind';

interface IRiskDataAutocompleteAsyncValueProps {
  product: string;
  dataIndex: string;
  dataSource: string;
  valueIndex: string;
  value: string | { label: string; value: string };
}

export const RiskDataAutocompleteAsyncValue = ({
  product,
  dataIndex,
  dataSource,
  valueIndex,
  value,
}: IRiskDataAutocompleteAsyncValueProps) => {
  const isValueValid = typeof value === 'object';

  const { data, isLoading } = useGetAutocompleteOptions({
    product,
    dataIndex,
    dataSource,
    valueIndex,
    enabled: !isValueValid,
  });

  if (!value) return <span />;

  if (isValueValid) return <span>{value?.label}</span>;

  if (isLoading) return <span>....</span>;

  const option = data?.find((item) => item.value === value);

  return <span>{option?.label ?? value}</span>;
};
